import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import PageContainer from "../components/page-container"
import SEO from "../components/seo"
import { SimpleContentLayer } from "../components/contentLayer/contentLayer"
import { Title } from "../components/content/title"
import { SectionParagraph } from "../components/content/paragraph"
import { Container } from "../components/container/container"
import { MenuWrapper } from "../components/container/menuWrapper"
import { Text } from "../components/base/typo"

import { AboutItem } from "../content/about/aboutItem"

import useWindowSize from "../hooks/useWindowSize"
import { useDimensions } from "../hooks/useDimensions"

import { SvgAbout1, SvgAbout2, SvgAbout3 } from "../assets/svg"
import { Button } from "../components/buttons/buttons"
import ROUTES from "../config/constants/route"
import {
  formatImageData,
  formatMarkdownData,
} from "../helpers/formatGraphqlData"
import { CustomReactMarkdown } from "../components/customReactMarkdown"

export const query = graphql`
  query ServicesPageQuery($locale: String) {
    strapiService(locale: { eq: $locale }) {
      title
      paragraph {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      sections {
        subtitle
        paragraph {
          data {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                sizes
                src
                srcSet
              }
            }
          }
        }
        featureList {
          title
          paragraph
          position
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1048) {
                  sizes
                  src
                  srcSet
                }
              }
            }
            name
          }
        }
      }
      bottomTitle
      bottomParagraph {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      buttomButtonText
    }
  }
`

const AboutPage = ({ data, intl }) => {
  const { type } = useWindowSize()
  const [ref, { width }] = useDimensions()
  const {
    title,
    paragraph,
    bottomTitle,
    bottomParagraph,
    buttomButtonText,
    sections,
  } = data.strapiService ?? {}

  const svgs = [SvgAbout1, SvgAbout2, SvgAbout3, SvgAbout2]

  return (
    <PageContainer>
      <SEO title="Services" />
      <div ref={ref}>
        <MenuWrapper pageName="services">
          <>
            <SimpleContentLayer>
              <Container>
                <Title bold>
                  <CustomReactMarkdown source={title} />
                </Title>
                <SectionParagraph>
                  <Text size="lg" weight="light">
                    <CustomReactMarkdown
                      source={formatMarkdownData(paragraph)}
                    />
                  </Text>
                </SectionParagraph>
              </Container>
            </SimpleContentLayer>
            {sections?.map((section, index) => (
              <div key={index}>
                <div className="py-2" />

                <AboutItem
                  index={`0${index + 1}`}
                  title={section?.subtitle}
                  paragraph={formatMarkdownData(section?.paragraph)}
                  featureList={section.featureList}
                  svg={svgs[index]}
                  img={formatImageData(section?.image)}
                  width={width}
                />
              </div>
            ))}

            <div className="py-2" />

            <SimpleContentLayer>
              <Container>
                <Title bold>
                  <CustomReactMarkdown source={bottomTitle} />
                </Title>
                <SectionParagraph>
                  <Text size="lg" weight="light">
                    <CustomReactMarkdown
                      source={formatMarkdownData(bottomParagraph)}
                    />
                  </Text>
                </SectionParagraph>
                <div className="py-2" />
                <Button link={ROUTES.CONTACT} text={buttomButtonText} />
              </Container>
            </SimpleContentLayer>

            <div className="py-8" />
          </>
        </MenuWrapper>
      </div>
    </PageContainer>
  )
}

export default injectIntl(AboutPage)
