import React from "react"
import styled from "@emotion/styled"
import tw from "tailwind.macro"

import { Title } from "../../components/content/title"
import { SectionParagraph } from "../../components/content/paragraph"
import { Container } from "../../components/container/container"
import { SimpleContentLayer } from "../../components/contentLayer/contentLayer"
import { Text } from "../../components/base"
import { AboutFeature } from "./aboutFeature"
import { CustomReactMarkdown } from "../../components/customReactMarkdown"

type AboutItemProps = {
  index: any
  title: any
  paragraph: any
  featureList: any
  svg: any
  img: any
  width: number
}

export const AboutItem = ({
  index,
  title,
  paragraph,
  featureList,
  svg,
  img,
  width,
}: AboutItemProps) => {
  const Svg = svg

  return (
    <div>
      <SimpleContentLayer>
        <Container>
          <div>
            <Text size="sm">{index}.</Text>
            <Title section={false}>{title}</Title>
          </div>
        </Container>
      </SimpleContentLayer>
      <ParentContainer>
        <img
          className="absolute"
          src={img}
          style={{ width: "100%", height: "100%" }}
        />
        <SvgContainer width={width}>
          <Svg />
        </SvgContainer>
      </ParentContainer>
      <SimpleContentLayer>
        <Container>
          <SectionParagraph>
            <Text size="lg" weight="light">
              <CustomReactMarkdown source={paragraph} />
            </Text>
          </SectionParagraph>
        </Container>
      </SimpleContentLayer>
      {featureList && <AboutFeature featureList={featureList} />}
    </div>
  )
}

const SvgContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  ${tw` `}
`

const ParentContainer = styled.div`
  ${tw`relative`}
`
