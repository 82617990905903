import { useState, useRef, useLayoutEffect, useEffect } from "react"

type DimensionsProps = {
  height: number
  width: number
}
export const useDimensions = () => {
  const ref = useRef()
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 }) as [
    DimensionsProps,
    any
  ]

  const updateDimensions = () => {
    if (ref.current) {
      const dim: DimensionsProps = ref.current.getBoundingClientRect()?.toJSON()
      setDimensions(dim)
    } else {
      setDimensions({ height: 0, width: 0 })
    }
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  useLayoutEffect(() => {
    updateDimensions()
  }, [ref.current])

  return [ref, dimensions] as const
}
