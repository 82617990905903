import React from "react"

import { SectionParagraph } from "../../components/content/paragraph"
import { Container } from "../../components/container/container"
import { SimpleContentLayer } from "../../components/contentLayer/contentLayer"
import { Text } from "../../components/base"
import { Section } from "../../components/base/layout"
import { formatImageData } from "../../helpers/formatGraphqlData"
import { CustomReactMarkdown } from "../../components/customReactMarkdown"

type AboutFeatureProps = {
  featureList: any
}

export const AboutFeature = ({ featureList }: AboutFeatureProps) => {
  return (
    <SimpleContentLayer>
      <Container width="large">
        <Section className="grid sm:grid-cols-1 row-gap-8 md:grid-cols-2 lg:grid-cols-3">
          {featureList
            ?.sort((a, b) => a.position - b.position)
            ?.map(({ image, title, paragraph }, index) => (
              <div key={index} className="mx-5 md:mb-5 sm:mb-5">
                {image !== null ? (
                  <img src={formatImageData(image)} alt={image?.name} />
                ) : (
                  ""
                )}
                <div className="py-5" />
                <Text size="3xl" weight="bold">
                  <CustomReactMarkdown source={title} />
                </Text>
                <SectionParagraph>
                  <Text size="lg" weight="light">
                    {paragraph}
                  </Text>
                </SectionParagraph>
              </div>
            ))}
        </Section>
      </Container>
    </SimpleContentLayer>
  )
}
